import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Utils from "../../utility";
import UserService from "../../services/userService";
import Auth0Service from "../../services/auth0Service";
import { removeUser } from "../../slices/userSlice";
import { useDispatch } from "react-redux";

import {
  failureMessageConstant,
  successMessageConstant,
  keyConstants,
  // eslint-disable-next-line
  regexConstant
} from "../../constants";
// eslint-disable-next-line
const { USER, ACCESS_TOKEN } = keyConstants;

function ChangePhoneNumberComponent() {
  //let classes = useStyles();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [errornewPhoneNumber, setErrornewPhoneNumber] = useState("");
  const [isPasswordMasked, setIsPasswordMasked] = useState(true);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const user = Utils.localStorageGetItem(USER);
  const dispatch = useDispatch();

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 3 && value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else if (value.length > 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
    setNewPhoneNumber(value);
  };

  // Regex to match US phone numbers in formats like: (xxx) xxx-xxxx, xxx-xxx-xxxx, or xxxxxxxxxx
  //const phoneRegex = /^(?:\+1\s?)?(\(\d{3}\)\s?|\d{3}-?)?\d{3}-?\d{4}$/;
  const validatePhoneNumberonBlur = (phone) => {
    const cleanedPhone = phone.replace(/\D/g, "");
    if (cleanedPhone.length === 10) {
      setErrornewPhoneNumber("");
      return true;
    } else {
      setErrornewPhoneNumber("Please enter a valid 10-digit US phone number.");
      return false;
    }
  };
  const currentPasswordValidate = async (password) => {
    try {
      setLoader(true);
      const auth0Service = new Auth0Service();
      const response = auth0Service.getToken({
        email: user?.email,
        password
      });
      const [result] = await Utils.parseResponse(response);
      if (result?.error || result?.response?.data?.error) {
        setLoader(false);
        throw new Error("current password is incorrect");
      }
      return true;
    } catch (err) {
      setLoader(false);
      return false;
    }
  };
  const changePhoneNumberHandler = async (e) => {
    e.preventDefault();
    const newPhoneNumberRegex = newPhoneNumber.replace(/\D/g, "");
    try {
      const checkCurrentPassword =
        await currentPasswordValidate(currentPassword);
      if (!checkCurrentPassword) {
        Utils.failureToastMessage(
          failureMessageConstant.CURRENT_PASSWORD_NOT_MATCHING
        );
        setLoader(false);
        return;
      }
      //const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
      const [err, changephoneNumber] = await Utils.parseResponse(
        new UserService().changePhoneNumber({
          id: user._id,
          phoneNumber: newPhoneNumberRegex
        })
      );
      if (err) {
        setLoader(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_UPDATING_USER
        );
      } else {
        setLoader(false);
        navigate("/?type=login");
        dispatch(removeUser({}));
        Utils.successToastMessage(
          successMessageConstant.CHANGE_PHONENUMBER_SUCCESSFULLY
        );
        return changephoneNumber;
      }
    } catch (error) {
      setLoader(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_CHANGING_PHONENUMBER
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="ml-285px flex flex-1 flex-col justify-between overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7">
        <div className=" my-28 flex w-full flex-col items-center">
          <div className="h-98 w-full max-w-110 rounded-4.5 bg-white px-7 py-8 shadow-modal">
            <div className="flex justify-center gap-1.5 font-Inter text-ft24-32 font-semibold">
              Change Phone Number
              <img
                src="/images/cross-icon-change-password.svg"
                alt="changePassword"
                className="relative left-15 w-3.5 cursor-pointer"
                onClick={() => {
                  navigate("/personal-details");
                }}
              />
            </div>
            <div className="mt-8 flex flex-col  justify-between gap-6">
              <div className="text-grey-757575 w-full font-Inter text-ft14-20 font-normal">
                <div className=" font-Inter text-ft16-19.36 font-medium text-black-15">
                  New Phone Number
                </div>
                <div className="flex">
                  <input
                    type={"tel"}
                    className={
                      "placeholder:text-grey-BDBDBD mt-0.5 h-49px w-97 rounded-lg border border-grey-E5E5E5 px-3 pr-12 text-black-15 outline-none placeholder:text-ft13-15 "
                    }
                    placeholder="Enter new Phone number"
                    value={newPhoneNumber}
                    maxLength="14"
                    onChange={handlePhoneNumberChange}
                    onBlur={() => validatePhoneNumberonBlur(newPhoneNumber)}
                  />
                </div>
                {errornewPhoneNumber && (
                  <div className=" text-xs mt-2 text-red-10">
                    {errornewPhoneNumber}
                  </div>
                )}
              </div>
              <div className="text-grey-757575 w-full font-Inter text-ft14-20 font-normal">
                <div className=" font-Inter text-ft16-19.36 font-medium text-black-15">
                  Current Password
                </div>
                <div className="flex">
                  <input
                    type={isPasswordMasked ? "password" : "text"}
                    className={`placeholder:text-grey-BDBDBD mt-0.5 h-49px w-97 rounded-lg border border-grey-E5E5E5 px-3 pr-12 text-black-15 outline-none placeholder:text-ft13-15 ${isPasswordMasked && currentPassword ? " text-ft24-32" : ""}`}
                    placeholder="Enter existing password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  {currentPassword && (
                    <img
                      src={
                        !isPasswordMasked
                          ? "/images/eye-open.png"
                          : "/images/cross-eye-icon.svg"
                      }
                      alt="eye-icon"
                      className={`ml-[-36px] cursor-pointer ${!isPasswordMasked ? " mt-3.5 h-27px w-5.5" : ""}`}
                      onClick={() => {
                        setIsPasswordMasked(!isPasswordMasked);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <button
              className="mt-12 h-40px w-97 rounded-lg bg-blue-146EF6 text-ft18-21 font-normal text-white"
              onClick={changePhoneNumberHandler}
            >
              Change Phone Number
              {/* {loader ? (
                <div className=" flex justify-center">
                  {" "}
                  <BiLoaderAlt className="size-10 animate-spin text-white" />
                </div>
              ) : (
                "Change Phone"
              )} */}
            </button>
          </div>
        </div>

        <div className="text-grey-757575 flex w-full justify-end pb-15 pr-11 font-Inter text-ft16-19.36 font-normal">
          LegalHero Helpline: 888-888-8888
        </div>
      </div>
    </>
  );
}
export default ChangePhoneNumberComponent;
