import HTTPService from "../utility/httpService";
import { apiEndPointConstants, keyConstants } from "../constants";
import Cookies from "../managers/sessionManager";
const {
  CHECK_USER_EXISTS,
  USER,
  CHANGE_PASSWORD,
  CHANGE_PHONENUMBER,
  RESEND_VERIFY,
  FORGET_PASSWORD,
  USER_LAST_LOGIN
} = apiEndPointConstants;
const { ACCESS_TOKEN } = keyConstants;

export default class UserService {
  constructor(token) {
    this.httpService = new HTTPService(process.env.REACT_APP_USER_SERVICE);
    this.config = {
      headers: {
        Authorization: `Bearer ${
          token !== undefined
            ? token
            : new Cookies().getCookieValue(ACCESS_TOKEN)
        }`
      }
    };
  }

  checkUserExists(requestData) {
    const url = `${CHECK_USER_EXISTS}?email=${requestData?.email}`;
    const requestBody = {};
    const headers = this.config;

    return this.httpService.get(url, requestBody, headers);
  }
  updateUserDetails(requestData) {
    const url = `${USER}`;
    const headers = this.config;
    if (!requestData.isEmailVerified) {
      return this.httpService.post(url, requestData, headers);
    } else {
      return this.httpService.put(url, requestData, headers);
    }
  }
  createUserDetails(requestData) {
    const url = `${USER}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  changePassword(requestData) {
    const url = `${CHANGE_PASSWORD}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  changePhoneNumber(requestData) {
    const url = `${CHANGE_PHONENUMBER}`;
    //const url = `http://localhost:3000/user/phone`;
    //const url = `/user/phone`;
    const headers = this.config;
    return this.httpService.put(url, requestData, headers);
  }
  resendVerifyEmail(requestData) {
    const url = `${RESEND_VERIFY}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  forgotPassword(requestData) {
    const url = `${FORGET_PASSWORD}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  userLastLogin(requestData) {
    const url = `${USER_LAST_LOGIN}`;
    const headers = this.config;

    return this.httpService.put(url, requestData, headers);
  }
}
