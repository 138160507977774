/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DatePickerComponent from "../../common/components/datePicker";
import DropDown from "../../common/components/dropdown";
import { useNavigate } from "react-router-dom";
import Utils from "../../utility";
import ClaimManagerService from "../../services/claimManagerService";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { BiLoaderAlt } from "react-icons/bi";
import {
  dropDownData,
  failureMessageConstant,
  keyConstants,
  regexConstant,
  successMessageConstant,
  tooltipContent
} from "../../constants";
import TermAndConditionPopup from "./termAndConditionPoup";
import Cookies from "../../managers/sessionManager";
const { USER, ASSISMENT_DETAIL, ACCESS_TOKEN } = keyConstants;
import { jwtDecode } from "jwt-decode";
const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #146EF6"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #146EF6",
    color: "#4A4A4A",
    width: "484px !important",
    height: "69px !important",
    borderRadius: "0px"
  }
}));

const PersonalDetailsComponent = () => {
  let classes = useStyles();
  const navigate = useNavigate();
  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
    } catch (e) {
      console.error("Invalid token");
    }
    return false;
  };
  const checkAccessToken = () => {
    const currentAccessToken = new Cookies().getCookieValue(ACCESS_TOKEN);
    if (!currentAccessToken || checkTokenExpiration(currentAccessToken)) {
      new Cookies().remove(ACCESS_TOKEN);
      Utils.localStorageClear();
      Utils.failureToastMessage(
        failureMessageConstant.SESSION_EXPIRED_PLEASE_LOGIN_AGAIN
      );
      navigate("/?type=login", { replace: true });
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(checkAccessToken, 2000);
    return () => clearInterval(interval);
  }, []);
  const convertEpochToDate = (epochTimeInSeconds) => {
    // Convert seconds to milliseconds
    const epochTimeInMilliseconds = epochTimeInSeconds * 1000;

    // Create a new Date object with the epoch time
    const date = new Date(epochTimeInMilliseconds);

    return date;
  };
  const convertDateToEpoch = (dateValue) => {
    // Create a new Date object with the specified date and time
    const date = new Date(dateValue);

    // Get the epoch time (in milliseconds)
    const epochTime = date.getTime();

    // Convert milliseconds to seconds (optional, as epoch time is often in seconds)
    const epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  };
  const assesmentDetails = Utils.localStorageGetItem(ASSISMENT_DETAIL);
  const [firstName, setFirstName] = useState(
    assesmentDetails !== null ? assesmentDetails.firstName : ""
  );
  const [lastName, setLastName] = useState(
    assesmentDetails !== null ? assesmentDetails.lastName : ""
  );
  const [dob, setDob] = useState(
    assesmentDetails !== null
      ? convertEpochToDate(assesmentDetails.dateOfBirth)
      : null
  );
  const [dateOfInjury, setDateOfInjury] = useState(
    assesmentDetails !== null
      ? convertEpochToDate(assesmentDetails.dateOfInjury)
      : null
  );
  const [placeOfInjury, setPlaceOfInjury] = useState(
    assesmentDetails !== null ? assesmentDetails.placeOfInjury : ""
  );
  const [dropdownValue, setDropDownValue] = useState(
    assesmentDetails !== null
      ? {
          name: assesmentDetails.state,
          value: assesmentDetails.state
        }
      : ""
  );
  const [loading, setLoading] = useState(false);
  const user = Utils.localStorageGetItem(USER);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState(
    assesmentDetails !== null ? assesmentDetails.email : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    assesmentDetails !== null ? assesmentDetails.primaryPhoneNumber : ""
  );
  const [address, setAddress] = useState(
    assesmentDetails !== null ? assesmentDetails.address : ""
  );
  const [socialNumber, setSocialNumber] = useState(
    assesmentDetails !== null ? assesmentDetails.socialSecurityNumber : ""
  );
  useEffect(() => {
    const hasSeenPopup = sessionStorage.getItem("hasSeenPopup");
    if (!hasSeenPopup) {
      setIsPopupOpen(true);
      sessionStorage.setItem("hasSeenPopup", "true");
    }
  }, []);

  const submitPersonalDetailHandler = async () => {
    if (!regexConstant.EMAIL_REGEX.test(email) && email) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_EMAIL);
      return;
    }
    if (socialNumber.length !== 11 && socialNumber) {
      Utils.failureToastMessage(
        failureMessageConstant.SOCIAL_SECURITY_NUMBER_REQUIRED_VALID
      );
      return;
    }
    if (phoneNumber.length !== 14 && phoneNumber) {
      Utils.failureToastMessage("Phone Number must be 10 digits long");
      return;
    }
    if (
      !firstName ||
      !lastName ||
      !convertDateToEpoch(dob) ||
      !convertDateToEpoch(dateOfInjury) ||
      !placeOfInjury ||
      !dropdownValue?.value ||
      !email ||
      !phoneNumber ||
      !address ||
      !socialNumber
    ) {
      Utils.failureToastMessage(
        failureMessageConstant.FILL_ALL_PERSONAL_DETAIL
      );
      return;
    }
    if (dob >= dateOfInjury) {
      Utils.failureToastMessage(
        "Date of Birth must be greater than Date of Injury"
      );
      return;
    }
    try {
      setLoading(true);
      if (assesmentDetails !== null) {
        const [err, personalDetail] = await Utils.parseResponse(
          new ClaimManagerService().updatePersonalDetail({
            assessmentId: assesmentDetails?._id,
            userId: user?._id,
            firstName,
            lastName,
            dateOfBirth: convertDateToEpoch(dob),
            dateOfInjury: convertDateToEpoch(dateOfInjury),
            placeOfInjury,
            state: dropdownValue?.value,
            email,
            primaryPhoneNumber: phoneNumber,
            address,
            socialSecurityNumber: socialNumber
          })
        );

        if (err) {
          Utils.failureToastMessage(
            failureMessageConstant.ERROR_WHILE_UPDATING_PERSONAL_DETAIL
          );
          setLoading(false);
        } else {
          Utils.localStorageSetItem(ASSISMENT_DETAIL, personalDetail);
          navigate("/injury-survey");
          setLoading(false);

          return personalDetail;
        }
      } else {
        const [err, personalDetail] = await Utils.parseResponse(
          new ClaimManagerService().addPersonalDetail({
            userId: user?._id,
            firstName,
            lastName,
            dateOfBirth: convertDateToEpoch(dob),
            dateOfInjury: convertDateToEpoch(dateOfInjury),
            placeOfInjury,
            state: dropdownValue?.value,
            email,
            primaryPhoneNumber: phoneNumber,
            address,
            socialSecurityNumber: socialNumber
          })
        );

        if (err) {
          Utils.failureToastMessage(
            failureMessageConstant.ERROR_WHILE_SUBMITING_PERSONAL_DETAIL
          );
          setLoading(false);
        } else {
          Utils.localStorageSetItem(ASSISMENT_DETAIL, personalDetail);
          navigate("/injury-survey");
          Utils.successToastMessage(
            successMessageConstant.SUBMITTED_PERSONAL_DETAIL_SUCCESSFULLY
          );
          setLoading(false);

          return personalDetail;
        }
      }
    } catch (error) {
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_SUBMITING_PERSONAL_DETAIL
      );
      setLoading(false);
    } finally {
      /* empty */
    }
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const validateDates = (newDob, newDateOfInjury) => {
    if (newDob && newDateOfInjury && newDob >= newDateOfInjury) {
      Utils.failureToastMessage(
        "Date of Birth must be greater than Date of Injury"
      );
      return;
    }
  };
  const handleDateOfBirthChange = (newDob) => {
    setDob(newDob);
    validateDates(newDob, dateOfInjury);
  };

  const handleDateOfInjuryChange = (newDateOfInjury) => {
    setDateOfInjury(newDateOfInjury);
    validateDates(dob, newDateOfInjury);
  };
  const handleSocialNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    if (value.length > 3 && value.length <= 5) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else if (value.length > 5) {
      value = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
    }
    setSocialNumber(value);
  };
  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    if (value.length > 3 && value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else if (value.length > 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
    setPhoneNumber(value);
  };
  return (
    <>
      <TermAndConditionPopup
        isPopupOpen={isPopupOpen}
        closePopup={closePopup}
      />

      {!isPopupOpen && (
        <div className=" mt-28 mb-10 flex w-full flex-col items-center">
          <div className="h-679px w-full max-w-152 rounded-4.5 bg-white px-7 py-8 shadow-modal">
            <div className="flex gap-1.5 font-Inter text-ft24-32 font-semibold items-center">
              Submit Personal Details
              <Tooltip
                title={
                  <span className=" font-Inter font-normal text-ft16-19.36 text-black-15 w-121 flex flex-col gap-1 mt-2.5">
                    <div>{tooltipContent[4]}</div>
                  </span>
                }
                arrow
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                placement="right-start"
                style={{ maxWidth: "484px" }}
                // leaveDelay={100000}
              >
                <Button className="submit-detail-tooltip">
                  <img
                    src="/images/help-icon.svg"
                    alt="info"
                    className="size-4.5 max-w-4.5"
                  />
                </Button>
              </Tooltip>
            </div>
            <div className="mt-8 flex justify-between  gap-4">
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>First Name</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\s+/g, ""); // Remove spaces
                    if (value.length <= 25) {
                      setFirstName(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Last Name</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\s+/g, ""); // Remove spaces
                    if (value.length <= 25) {
                      setLastName(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-between gap-4">
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Date of Birth</div>
                <DatePickerComponent
                  selectedDate={dob}
                  setSelectedDate={handleDateOfBirthChange}
                  type="dob"
                  minDate={new Date(1900, 0, 1)}
                  maxDate={new Date()}
                />
              </div>
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Date of Injury</div>
                {console.log("setSelectedDate", dob)}
                <DatePickerComponent
                  selectedDate={dateOfInjury}
                  setSelectedDate={handleDateOfInjuryChange}
                  type="dateOfInjury"
                  minDate={dob === null ? new Date(1900, 0, 1) : dob}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-between gap-4">
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Place of Injury</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Where the injury was happened"
                  value={placeOfInjury}
                  onChange={(e) => {
                    setPlaceOfInjury(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>State</div>
                <DropDown
                  data={dropDownData}
                  value={dropdownValue}
                  setValue={setDropDownValue}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-between  gap-4">
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Email</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Enter email ID"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                  }}
                />
              </div>
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Primary phone number</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Enter your contact number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  maxLength="14"
                />
              </div>
            </div>
            <div className="mt-6 flex justify-between  gap-4">
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Address</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Where do you reside"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/2 font-Inter text-ft14-20 font-normal text-customBlack">
                <div>Social Security Number</div>
                <input
                  type="text"
                  className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                  placeholder="Enter social security number"
                  value={socialNumber}
                  onChange={handleSocialNumberChange}
                  maxLength="11"
                />
              </div>
            </div>
            <button
              className="mt-12 h-53px w-full rounded-lg bg-blue-146EF6 text-ft18-21 font-normal text-white"
              onClick={submitPersonalDetailHandler}
              disabled={loading}
            >
              {loading ? (
                <div className=" flex justify-center">
                  {" "}
                  <BiLoaderAlt className="size-10 animate-spin text-white" />
                </div>
              ) : (
                "Submit and Continue"
              )}
            </button>
          </div>
        </div>
      )}
      {!isPopupOpen && (
        <div className="w-full flex justify-end pb-2 pr-11 font-Inter font-normal text-ft16-19.36 text-customBlack">
          LegalHero Helpline: 888-888-8888
        </div>
      )}
    </>
  );
};

export default PersonalDetailsComponent;
