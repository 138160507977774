/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/classnames-order */
import React, { useEffect, useState } from "react";
import HistoryComponent from "./historyComponent";
import "./historyComponent.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  failureMessageConstant,
  historyDummyIds,
  keyConstants
} from "../../constants";
import Utils from "../../utility";
import AdminService from "../../services/adminService";
const { ASSISMENT_DETAIL, INJURY_TYPE, SURVEY_QUESTIONS, USER } = keyConstants;

function History() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentAssessment, setCurrentAssessment] = useState(0);
  const [assismentDetails, setAssismentDetails] = useState([]);
  const [userDetailData, setUserDetailData] = useState();
  const user = Utils.localStorageGetItem(USER);
  const [loader, setLoader] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAssessments, setFilteredAssessments] = useState([]);

  useEffect(() => {
    const userDetailData = async () => {
      await userDetail();
    };
    userDetailData();
  }, []);
  const userDetail = async () => {
    try {
      setLoader(true);
      let requestBody;
      requestBody = {
        userID: user._id
      };
      const response = await new AdminService().getUserDetail(requestBody);
      if (!response) {
        setLoader(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_FETCHING_USER_DETAIL
        );
      } else {
        //setUserDetailData(response);
        //should remove this and make the data dynamic when history and assessment API is ready
        const mockedStaticdata = {
          addedOn: 1731914282648,
          assessments: [
            {
              reports: [
                {
                  assessmentid: "SSA0584",
                  Nameofinjured: "John Appleseed",
                  Dateofbirth: "11/09/1994",
                  Dateofinjury: "04/12/2024",
                  Placeofinjury: "Middletown, NY"
                },
                {
                  assessmentid: "SSA0585",
                  Nameofinjured: "Derek Murphy",
                  Dateofbirth: "11/09/1970",
                  Dateofinjury: "04/12/2024",
                  Placeofinjury: "Middletown, NY"
                },
                {
                  assessmentid: "SSA0586",
                  Nameofinjured: "Amanda McDonalds",
                  Dateofbirth: "11/09/1963",
                  Dateofinjury: "04/12/2024",
                  Placeofinjury: "Middletown, NY"
                },
                {
                  assessmentid: "SSA0587",
                  Nameofinjured: "Donald trump",
                  Dateofbirth: "11/09/1923",
                  Dateofinjury: "04/12/2024",
                  Placeofinjury: "Middletown, NY"
                }
              ]
            }
          ],
          email: "kaviarasan202203@gmail.com",
          fullName: "kaviarasan",
          invitedById: "66d16bd99d1e7268c171a8c7",
          isActive: true,
          isDeleted: false,
          isEmailVerified: true,
          lastLogin: 1732256537799,
          modifiedOn: 1731914371976,
          userId: "auth0|673aea5ac8b79b04bfae4457",
          _id: "673aea2af574ea0025c485d7"
        };
        setUserDetailData(mockedStaticdata);
        const filteredAssessments = mockedStaticdata[0]?.assessments[0]?.filter(
          (item) => item.reports.length > 0
        );

        setCurrentAssessment(filteredAssessments?.[0]?._id);
        setAssismentDetails(filteredAssessments?.[0]);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_FETCHING_USER_DETAIL
      );
    }
  };
  useEffect(() => {
    if (userDetailData) {
      const filteredReports = userDetailData.assessments[0].reports.filter(
        (item) => {
          if (searchQuery && item.Nameofinjured) {
            return item.Nameofinjured.toLowerCase().includes(
              searchQuery.toLowerCase()
            );
          }
          return true;
        }
      );
      console.log("filteredReports", filteredReports);
      setFilteredAssessments(filteredReports);
    } else {
      console.log("no data");
    }
  }, [userDetailData, searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex flex-1 justify-between flex-col  overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7">
      <div className="h-full w-285px md:block fixed bg-white">
        <div className="sticky top-0 left-0 h-[calc(100vh-60px)] w-full flex-col justify-between gap-1 py-4 md:flex">
          <div>
            <div className="mt-4 pl-4 font-Inter text-ft26-31 font-semibold text-black-15">
              Calculate your injury compensation
            </div>
            <div className="px-4">
              {(location.pathname === "/faq" ||
                location.pathname === "/history") && (
                <button
                  className="mt-3.5 flex h-11 w-full cursor-pointer items-center justify-center rounded-md border-none bg-[#146EF6] text-ft15-17 font-medium font-SF text-white outline-none"
                  onClick={() => {
                    Utils.localStorageRemoveItem(ASSISMENT_DETAIL);
                    Utils.localStorageRemoveItem(INJURY_TYPE);
                    Utils.localStorageRemoveItem(SURVEY_QUESTIONS);
                    navigate("/personal-details");
                  }}
                >
                  New Assessment
                </button>
              )}
            </div>

            {location.pathname === "/history" && (
              <div className="mt-10 px-0.5">
                <div className="font-Inter text-ft18-21 pl-4 font-semibold text-black-15 border-b border-grey-E3E3E3 pb-5">
                  History
                </div>
                <input
                  type="text"
                  //className="border p-2 rounded"
                  className="search-input"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <div className="flex flex-col h-[456px] overflow-y-auto">
                  {filteredAssessments?.length > 0 ? (
                    filteredAssessments.map((item, index) => (
                      <div
                        key={index}
                        className={`font-Inter flex items-center pl-4 min-h-11.5 h-11.5 text-ft15-18.5 border-b border-grey-E3E3E3 cursor-pointer ${
                          item.assessmentid === currentAssessment
                            ? "text-blue-146EF6 bg-grey-FAFAFA font-semibold"
                            : "text-black-15 font-normal"
                        }`}
                        onClick={() => {
                          setCurrentAssessment(item.assessmentid);
                          setAssismentDetails(item);
                        }}
                      >
                        <pre>
                          {/* <div className={`font-PoppinsMedium text-black`}>
                            {item?.Nameofinjured}
                          </div> */}
                          {/* <div  className="font-intersansserif text-tiny font-[400] leading-14.52 text-left text-grey">
                            {item?.Nameofinjured}
                          </div> */}
                          <div
                            style={{
                              fontFamily: "Inter",
                              fontSize: "15px",
                              fontWeight: 500,
                              lineHeight: "18.15px",
                              textAlign: "left",
                              textUnderlinePosition: "from-font",
                              textDecorationSkipInk: "none"
                            }}
                          >
                            {item?.Nameofinjured}
                          </div>
                          <div className="font-intersansserif text-tiny leading-14.52 text-left text-grey">
                            {" "}
                            {item?.assessmentid} {item?.Dateofinjury}
                          </div>
                          {/* <div
                            style={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                              lineHeight: "14.52px",
                              textAlign: "left",
                              textUnderlinePosition: "from-font",
                              textDecorationSkipInk: "none",
                              color: "#868c8c",
                            }}
                          >
                            {item?.assessmentid} {item?.Dateofinjury}
                          </div> */}
                        </pre>
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-black-15">
                      No assessments found
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mb-20 px-4 font-Inter text-ft15-17 font-medium">
            <div
              className={`flex cursor-pointer items-center justify-between border-y border-grey-E3E3E3 py-3.5 ${location.pathname === "/history" ? "text-blue-146EF6" : "text-black-15"}`}
              onClick={() => {
                navigate("/history");
              }}
            >
              History
              <img
                src={
                  location.pathname === "/history"
                    ? "/images/arrow-colored-icon.svg"
                    : "/images/right-arrow.svg"
                }
                alt="arrow"
              />
            </div>
            <div
              className={`flex items-center cursor-pointer justify-between border-b border-grey-E3E3E3 py-3.5 ${location.pathname === "/faq" ? "text-blue-146EF6" : "text-black-15"}`}
              onClick={() => {
                navigate("/faq");
              }}
            >
              FAQs
              <img
                src={
                  location.pathname === "/faq"
                    ? "/images/arrow-colored-icon.svg"
                    : "/images/right-arrow.svg"
                }
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col items-center ml-285px ">
        <HistoryComponent
          userDetailData={userDetailData}
          currentAssessment={currentAssessment}
          assismentDetails={assismentDetails}
          loader={loader}
        />
        <div className="w-full flex justify-end pb-15 pr-11 font-Inter font-normal text-ft16-19.36 text-grey-757575 mt-20">
          LegalHero Helpline: 888-888-8888
        </div>
      </div>
    </div>
  );
}

export default History;
