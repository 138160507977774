/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable react/no-unknown-property */
/* eslint-disable tailwindcss/no-unnecessary-arbitrary-value */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import { ReactComponent as LoginContent } from "../../assets/images/login-content.svg";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import { useForm } from "react-hook-form";
import { failureMessageConstant, regexConstant } from "../../constants";
import Utils from "../../utility";
import { useNavigate } from "react-router";
const {
  NAME_REQUIRED,
  MAX_CHARS,
  EMAIL_REQUIRED,
  INVALID_EMAIL,
  PASSWORD_REQUIRED,
  INVALID_PASSWORD,
  PASSWORD_MATCH_ERROR
} = failureMessageConstant;

const LoginComponent = ({
  checkUserAlreadyExistsOrNot,
  statusVerificationEmail,
  isPasswordEmailValid,
  usersEmail,
  accountExistsSignIn,
  setStatusVerificationEmail,
  setLoginPage,
  handleOAuthSignIn
}) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm();
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loginInputs, setLoginInputs] = useState({ email: "", password: "" });
  const [signUpInputs, setSignUpInputs] = useState({ email: "", password: "" });
  const params = new URLSearchParams(window.location.search);
  let messageData = params.get("message");
  const navigate = useNavigate();
  const [state, setState] = useState({
    loginState: "signUp",
    button: "Create Account",
    googleButton: "Sign Up with Google"
  });
  // const [phoneNumber, setphoneNumber] = useState("");
  // const [errorphoneNumber, setErrorphoneNumber] = useState("");
  // const [newPhoneNumber, setNewPhoneNumber] = useState("");
  //   const [errornewPhoneNumber, setErrornewPhoneNumber] = useState("");
  const [timeLeft, setTimeLeft] = useState(45); // Initial timer value in seconds

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer on component unmount
    }
  }, [timeLeft]);

  // Format timeLeft as MM:SS
  const formatTime = () => {
    const minutes = String(Math.floor(timeLeft / 60)).padStart(2, "0");
    const seconds = String(timeLeft % 60).padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  useEffect(() => {
    if (messageData) {
      setLoginPage(true);
      setState({
        loginState: "login",
        button: "Sign In",
        googleButton: "Log In with Google"
      });
      Utils.successToastMessage("Email verified. Log in to continue");
    }
  }, []);
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const type = params.get("type");

    if (type === "login") {
      setLoginPage(true);
      setState({
        loginState: "login",
        button: "Sign In",
        googleButton: "Log In with Google"
      });
    }
  }, []);
  useEffect(() => {
    if (accountExistsSignIn) {
      setState((prev) => ({
        ...prev,
        loginState: "login",
        button: "Sign In",
        googleButton: "Log In with Google"
      }));
    }
  }, [accountExistsSignIn]);
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let email = params.get("email") || "";
    email = email ? email.replace(/\s/g, "+") : "";
    setValue("email", email);
    setSignUpInputs({ email: email });
    let name = params.get("name") || "";
    setValue("name", name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, state?.loginState]);
  useEffect(() => {
    if (statusVerificationEmail) {
      setState((prev) => ({
        ...prev,
        loginState: "verificationEmail",
        button: "Send Email Again",
        googleButton: "Log In with Google"
      }));
      return;
    }
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set("type", state.loginState === "signUp" ? "signUp" : "login");
    url.search = params.toString();
    window.history.replaceState(null, "", url.toString());
  }, [state.loginState, statusVerificationEmail]);
  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSignUpInputChange = (e) => {
    const { name, value } = e.target;
    setSignUpInputs((prev) => ({ ...prev, [name]: value }));
  };
  const redirectToLogin = () => {
    setStatusVerificationEmail(false);
    setState((prev) => ({
      ...prev,
      loginState: "login",
      button: "Sign In",
      googleButton: "Log In with Google"
    }));
  };
  useEffect(() => {
    if (statusVerificationEmail) {
      setState((prev) => ({
        ...prev,
        loginState: "verificationEmail",
        button: "Send Email Again",
        googleButton: "Log In with Google"
      }));
      return;
    }
    // if (loginPage) {
    //   setState((prev) => ({
    //     ...prev,
    //     loginState: "login",
    //     button: "Send Email Again",
    //     googleButton: "Log In with Google"
    //   }));
    // }
  }, [statusVerificationEmail]);

  //const phoneRegex = /^(?:\+1\s?)?(\(\d{3}\)\s?|\d{3}-?)?\d{3}-?\d{4}$/;
  const validatePhoneNumberonBlur = (phone) => {
    // Remove non-digit characters
    const cleanedPhone = phone.replace(/\D/g, "");
    if (cleanedPhone.length === 10) {
      return true;
    } else {
      return false;
    }
  };
  const checkvalidation = () => {
    if (state.loginState === "verificationEmail") return true;
    const nameInput = document.getElementById("name")?.value.trim();
    const emailInput = document.getElementById("emailValue")?.value.trim();
    const passwordInput = document.getElementById("Pass")?.value.trim();
    const phoneNumber = document.getElementById("phoneNumber")?.value.trim();
    const confPasswordInput = document
      .getElementById("confirmPass")
      ?.value.trim();
    const maxLength = 30;
    if (nameInput?.length > maxLength && state.loginState === "signUp") {
      Utils.failureToastMessage(failureMessageConstant.MAX_CHARS);
      return false;
    }
    if (!regexConstant.EMAIL_REGEX.test(emailInput) && emailInput) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_EMAIL);
      return;
    }
    if (
      !regexConstant.PASSWORD_REGEX.test(passwordInput) &&
      passwordInput &&
      state.loginState === "signUp"
    ) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_PASSWORD);
      return;
    }
    if (
      !regexConstant.PASSWORD_REGEX.test(confPasswordInput) &&
      confPasswordInput &&
      state.loginState === "signUp"
    ) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_COFIRM_PASSWORD);
      return;
    }
    if (!nameInput?.length && state.loginState === "signUp") {
      Utils.failureToastMessage(failureMessageConstant.NAME_REQUIRED);
      return false;
    }
    if (!emailInput?.length) {
      Utils.failureToastMessage(failureMessageConstant.EMAIL_REQUIRED);
      return false;
    }
    if (!passwordInput?.length) {
      Utils.failureToastMessage(failureMessageConstant.PASSWORD_REQUIRED);
      return false;
    }
    if (!confPasswordInput?.length && state.loginState === "signUp") {
      Utils.failureToastMessage(
        failureMessageConstant.COFIRM_PASSWORD_REQUIRED
      );
      return false;
    }
    if (passwordInput !== confPasswordInput && state.loginState === "signUp") {
      Utils.failureToastMessage(failureMessageConstant.PASSWORD_MATCH_ERROR);
      return false;
    }
    if (state.loginState === "signUp" && !phoneNumber?.length) {
      Utils.failureToastMessage(failureMessageConstant.PHONE_NUMBER_REQUIRED);
      return false;
    }
    if (
      state.loginState === "signUp" &&
      !validatePhoneNumberonBlur(phoneNumber)
    ) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_PHONE_NUMBER);
      return false;
    }
    return true; // Return true if validation passes
  };

  useEffect(() => {
    setValue("password", "");
    setValue("confirmPassword", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.loginState]);

  return (
    <>
      <div className="flex size-full">
        <div className=" flex min-h-[100vh] w-full flex-col items-center justify-between bg-white pt-24 pb-10 gap-10">
          <div className=" flex items-center flex-col">
            <Logo />
            <div className=" mt-[40px] h-auto w-[420px] rounded-lg shadow-2xl">
              <div className=" flex h-[50px] w-full items-center">
                <div
                  onClick={() => {
                    clearErrors();
                    setStatusVerificationEmail(false);
                    setLoginPage(false);
                    const url = new URL(window.location.href);
                    const params = new URLSearchParams(url.search);
                    let email = params.get("email") || "";
                    email = email ? email.replace(/\s/g, "+") : "";
                    setSignUpInputs({ email: email, password: "" });
                    setState((prev) => ({
                      ...prev,
                      loginState: "signUp",
                      button: "Create Account",
                      googleButton: "Sign Up with Google"
                    }));
                  }}
                  className={`flex h-[50px] w-1/2 cursor-pointer items-center justify-center font-Inter text-ft16-24 font-medium ${state.loginState === "signUp" || state.loginState === "verificationEmail" ? "border-b border-b-[#146EF6] text-[#146EF6]" : "border-b border-b-[#E0E0E0] text-black-15"}`}
                >
                  Sign Up
                </div>
                <div
                  onClick={() => {
                    clearErrors();
                    setStatusVerificationEmail(false);
                    setLoginPage(true);
                    setLoginInputs({ email: "", password: "" });
                    setState((prev) => ({
                      ...prev,
                      loginState: "login",
                      button: "Sign In",
                      googleButton: "Log In with Google"
                    }));
                  }}
                  className={`flex h-[50px] w-1/2 cursor-pointer items-center justify-center font-Inter text-ft16-24 font-medium ${state.loginState === "login" ? "border-b border-b-[#146EF6] text-[#146EF6]" : "border-b border-b-[#E0E0E0] text-black-15"}`}
                >
                  Log In
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault(); // Prevent the default form submission
                  if (checkvalidation()) {
                    // Check validation first
                    handleSubmit(checkUserAlreadyExistsOrNot)(e); // If validation passes, call handleSubmit
                  }
                }}
              >
                <div className="flex flex-col items-start px-4.5 py-23px">
                  {state.loginState === "verificationEmail" && (
                    <div className="mx-auto mt-5">
                      <div className="relative mx-auto">
                        <div className="h-63px w-63px rounded-full bg-blue-E7F0FF"></div>
                        <div className="absolute right-0.5 top-2.5 w-18">
                          <img src="/images/inbox.svg" />
                        </div>
                      </div>
                    </div>
                  )}
                  {state.loginState === "verificationEmail" && (
                    <div className="mx-auto mt-8 font-Inter text-ft16-24 font-medium text-black-15">
                      We have sent a verification email for verification
                    </div>
                  )}
                  {state.loginState === "verificationEmail" && (
                    <div className="mx-auto mt-1 w-80 text-center font-Inter text-ft15-17 font-normal text-black-15">
                      Please click the verification link in your email to
                      continue the injury compensation assessment process.
                    </div>
                  )}
                  {state.loginState === "signUp" && (
                    <div className="w-full">
                      <label className=" font-Inter text-ft16-24 font-medium">
                        Full Name
                      </label>
                      <input
                        className=" flex h-[49px] w-full items-center rounded border border-[#E5E5E5] px-4 text-black-15 placeholder:text-grey-919191 outline-none font-Inter font-normal text-ft15-18.5"
                        placeholder="Enter full name"
                        id="name"
                        name="name"
                        {...register("name", {
                          required: NAME_REQUIRED
                        })}
                      />
                      {!!errors?.name && (
                        <div className="mt-2 flex items-center  font-Inter text-ft12-16 text-red-10">
                          <p>{errors?.name?.message}</p>
                        </div>
                      )}
                    </div>
                  )}
                  {state.loginState != "verificationEmail" && (
                    <>
                      <div className="mt-[20px] w-full">
                        <label className="font-Inter text-ft16-24 font-medium">
                          Email
                        </label>
                        <input
                          id="emailValue"
                          disabled={
                            usersEmail && state?.loginState === "signUp"
                              ? true
                              : false
                          }
                          {...register("email", {
                            required: EMAIL_REQUIRED,
                            pattern: {
                              value: regexConstant.EMAIL_REGEX,
                              message: INVALID_EMAIL
                            }
                          })}
                          className="flex h-[49px] w-full items-center rounded border border-[#E5E5E5] px-4 text-black-15 placeholder:text-grey-919191 outline-none font-Inter font-normal text-ft15-18.5"
                          placeholder="Enter your email"
                          value={
                            state.loginState === "login"
                              ? loginInputs.email
                              : signUpInputs.email
                          }
                          onChange={
                            state.loginState === "login"
                              ? handleLoginInputChange
                              : handleSignUpInputChange
                          }
                        />
                        {!!errors?.email && (
                          <div className="mt-2 flex items-center font-Inter text-ft12-16 text-red-10">
                            <p>{errors?.email?.message}</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {state.loginState === "signUp" && (
                    <div className="mt-[20px]">
                      <label className="font-Inter text-ft16-24 font-medium">
                        Phone
                      </label>
                      <div className="flex">
                        <input
                          id="phoneNumber"
                          className={`flex h-[49px] w-[386px] items-center rounded border border-[#E5E5E5] pl-4 pr-11 
                        font-Inter font-normal text-black-15 placeholder:text-grey-919191 outline-none 
                        placeholder:text-ft15-18.5`}
                          placeholder="Enter your phone number"
                          type="tel"
                          {...register("phoneNumber", {
                            required:
                              failureMessageConstant.PHONE_NUMBER_REQUIRED,
                            pattern: {
                              value:
                                /^(?:\+1\s?)?(\(\d{3}\)\s?|\d{3}-?)?\d{3}-?\d{4}$/,
                              message:
                                failureMessageConstant.INVALID_PHONE_NUMBER
                            },
                            maxLength: 10
                          })}
                          maxLength={10}
                          onKeyDown={(e) => {
                            const allowedKeys = [
                              "Backspace",
                              "Tab",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                              "ArrowUp",
                              "ArrowDown"
                            ];
                            if (allowedKeys.includes(e.key)) {
                              return;
                            }
                            if (!/^[0-9]$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      {!!errors?.phoneNumber && (
                        <div className="mt-2 flex items-start font-Inter text-ft12-16 text-red-10">
                          <p>{errors?.phoneNumber?.message}</p>
                        </div>
                      )}
                    </div>
                  )}
                  {state.loginState != "verificationEmail" && (
                    <>
                      <div className="mt-[20px] w-full">
                        <label className="font-Inter text-ft16-24 font-medium">
                          Password
                        </label>
                        <div className="flex">
                          <input
                            id="Pass"
                            className={`flex h-[49px] w-full items-center rounded border border-[#E5E5E5] pl-4 pr-11 font-Inter text-ft15-18.5 font-normal text-black-15 placeholder:text-grey-919191 outline-none placeholder:text-ft15-18.5 ${passwordVisible ? "text-ft15-18.5" : ""}`}
                            placeholder="Enter your password"
                            type={passwordVisible ? "text" : "password"}
                            {...register("password")}
                            value={
                              state.loginState === "login"
                                ? loginInputs.password
                                : signUpInputs.password
                            }
                            onChange={
                              state.loginState === "login"
                                ? handleLoginInputChange
                                : handleSignUpInputChange
                            }
                          />
                          <img
                            src={
                              passwordVisible
                                ? "/images/eye-open.png"
                                : "/images/cross-eye-icon.svg"
                            }
                            alt="eye-icon"
                            className={`ml-[-36px] cursor-pointer ${passwordVisible ? " mt-2.5 h-27px w-5.5" : ""}`}
                            onClick={() => {
                              setPasswordVisible(!passwordVisible);
                            }}
                          />
                          <style jsx>{`
                            #Pass:placeholder-shown + img {
                              display: none;
                            }
                          `}</style>
                        </div>
                        {(errors?.password || isPasswordEmailValid) && (
                          <div className="mt-2 flex items-start font-Inter text-ft12-16 text-red-10">
                            <p>
                              {errors?.password
                                ? errors?.password?.message
                                : isPasswordEmailValid}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {state.loginState === "signUp" && (
                    <div className=" mt-[20px]">
                      <label className=" font-Inter text-ft16-24 font-medium">
                        Confirm Password
                      </label>
                      <div className="flex">
                        <input
                          id="confirmPass"
                          className={`flex h-[49px] w-[386px] items-center rounded border border-[#E5E5E5] pl-4 pr-11 font-Inter font-normal text-black-15 placeholder:text-grey-919191 outline-none placeholder:text-ft15-18.5 ${confirmPasswordVisible ? "text-ft15-18.5" : ""}`}
                          placeholder="Confirm your password"
                          type={confirmPasswordVisible ? "text" : "password"}
                          {...register("confirmPassword", {
                            required: PASSWORD_REQUIRED,
                            validate: (value) =>
                              confirmPassword === password ||
                              PASSWORD_MATCH_ERROR,
                            minLength: {
                              value: 8,
                              message: INVALID_PASSWORD
                            }
                          })}
                        />
                        <img
                          src={
                            confirmPasswordVisible
                              ? "/images/eye-open.png"
                              : "/images/cross-eye-icon.svg"
                          }
                          alt="eye-icon"
                          className={`ml-[-36px] cursor-pointer ${confirmPasswordVisible ? " mt-2.5 h-27px w-5.5" : ""}`}
                          onClick={() => {
                            setConfirmPasswordVisible(!confirmPasswordVisible);
                          }}
                        />
                        <style jsx>{`
                          #confirmPass:placeholder-shown + img {
                            display: none;
                          }
                        `}</style>
                      </div>
                      {!!errors?.confirmPassword && (
                        <div className="mt-2 flex items-start font-Inter text-ft12-16 text-red-10">
                          <p>{errors?.confirmPassword?.message}</p>
                        </div>
                      )}
                    </div>
                  )}
                  <button className="mt-25px flex h-[51px] w-full cursor-pointer items-center justify-center rounded border-none bg-[#146EF6] font-Inter text-ft16-24 font-medium text-white outline-none">
                    {state.button}
                  </button>
                  {state.button === "Send Email Again" && (
                    <div className=" mx-auto mt-6.5 font-Inter text-ft14-20 font-normal text-grey-51575B">
                      Email already verified?{" "}
                      <span className="mx-auto mt-8 font-Inter text-ft16-24 font-medium text-black-15">
                        {formatTime()}
                      </span>
                    </div>
                  )}
                  {state.loginState === "verificationEmail" && (
                    <div className=" mx-auto mt-6.5 font-Inter text-ft14-20 font-normal text-grey-51575B">
                      Email already verified?{" "}
                      <span
                        onClick={redirectToLogin}
                        className="cursor-pointer text-blue-146EF6"
                      >
                        Log In
                      </span>
                    </div>
                  )}
                  {state.loginState === "login" && (
                    <div className="mb-3 mt-5 flex w-full justify-center font-Inter text-ft14-20 font-normal">
                      Forgot your password?{" "}
                      <span
                        className="ml-1 text-blue-146EF6 cursor-pointer"
                        onClick={() => {
                          navigate("/forget-password");
                        }}
                      >
                        Reset it here
                      </span>
                    </div>
                  )}
                  {state.loginState != "verificationEmail" && (
                    <div className="mt-3.5 flex w-full items-center">
                      <hr className="h-px w-full text-grey-E0E0E0" />
                      <div className="mx-2 whitespace-nowrap font-Inter text-ft14-20 font-normal text-grey-757575">
                        OR
                      </div>
                      <hr className="w-full text-grey-E0E0E0" />
                    </div>
                  )}
                  {state.loginState != "verificationEmail" && (
                    <button
                      type="button"
                      className="mt-3.5 flex h-[49px] w-[386px] cursor-pointer items-center justify-center gap-1 border border-grey-E5E5E5 bg-white font-Inter text-ft16-24 font-medium text-black-10 outline-none"
                      onClick={handleOAuthSignIn}
                    >
                      <img
                        src="/images//google-icon.svg"
                        alt="googleIcon"
                        className="mr-2"
                      />
                      {state.googleButton}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="h-9 w-41.5 text-center font-Inter text-ft15-17 font-medium tracking-tight text-black-15">
            2024 All Right Reserved Privacy and Terms
          </div>
        </div>
        <div className=" flex min-h-[100vh] w-full max-w-[680px] flex-col items-center bg-[#146EF6]">
          <span className="mt-[12%] w-full max-w-[75%] text-center text-ft30-36 font-bold text-white">
            Discover Your Injury Compensation Value with Ease!
          </span>
          <LoginContent className=" mt-[13%]" />
        </div>
      </div>
    </>
  );
};
export default LoginComponent;
