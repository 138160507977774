/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import LoginComponent from "./loginComponent";
import UserService from "../../services/userService";
import Utils from "../../utility";
import Auth0Service from "../../services/auth0Service";
import {
  failureMessageConstant,
  keyConstants,
  successMessageConstant,
  errorCode
} from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUser,
  createUser,
  removeUser,
  showLoader,
  hideLoader
} from "../../slices/userSlice";
import { CommonLoader } from "../../common/components/loader";
const { ID, USER, AUTH_ID, EMAIL_ID, IS_GOOGLE_LOGIN } = keyConstants;
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let userId = useSelector((state) => state.user?.id);
  const [statusVerificationEmail, setStatusVerificationEmail] = useState(false);
  const [loginPage, setLoginPage] = useState(false);
  const [accountExistsSignIn, setAccountExistsSignIn] = useState(false);
  const [isPasswordEmailValid, setIsPasswordEmailValid] = useState("");
  const params = new URLSearchParams(window.location.search);
  const url = new URL(window.location.href);
  const emailValue = new URLSearchParams(url.search);
  let usersEmail = emailValue.get("email") || "";
  const id = params.get("id");
  const [updatedUserDetails, setUpdatedUserDetails] = useState({});
  const [createdUserDetails, setCreatedUserDetails] = useState({});
  const [callbackLoader, setCallbackLoader] = useState(false);

  // setting the user id as user is coming from mail
  Utils.localStorageSetItem(ID, id);
  const checkEmail = (email) => {
    // Define a regex pattern to detect special characters
    const specialCharPattern = /[!#$%&'*+/=?^_`{|}~]/;

    // Check if email contains any special characters
    if (specialCharPattern.test(email)) {
      return encodeURIComponent(email);
    } else {
      return email;
    }
  };

  // CHECKING IF USER HAS NOT VERIFIED MAIL AND TRYING TO LOGIN
  useEffect(() => {
    if (location?.hash?.includes(errorCode.USER_NOT_VERIFIED)) {
      setStatusVerificationEmail(true);
      dispatch(removeUser({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // FUNCTION TO UPDATE THE USER DETAILS
  const updateUserDetail = async (userInfo, userDetails) => {
    try {
      const [err, updateUserRes] = await Utils.parseResponse(
        new UserService().updateUserDetails({
          id: userDetails?._id || userId,
          userId: userInfo?.sub,
          email: userInfo?.email,
          isEmailVerified: userInfo?.email_verified,
          fullName: userInfo?.name,
          isActive: true,
          phoneNumber: userInfo?.phoneNumber
        })
      );
      if (err) {
        Utils.failureToastMessage(err?.response?.data?.message);
      } else {
        setUpdatedUserDetails(updateUserRes);
        if (updateUser?.isEmailVerified) {
          Utils.successToastMessage(successMessageConstant.SIGIN_SUCCESS);
        }

        return updateUserRes;
      }
    } catch (error) {
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_UPDATING_USER
      );
    } finally {
      /* empty */
    }
  };

  // FUNCTION TO CREATE THE USER DETAILS
  const createUserDetail = async (userInfo) => {
    try {
      const [err, createUserRes] = await Utils.parseResponse(
        new UserService().createUserDetails({
          id: userInfo?.Id || userId,
          userId: userInfo?.Id,
          email: userInfo?.email,
          fullName: userInfo?.name,
          phoneNumber: userInfo?.phoneNumber
        })
      );
      if (err) {
        Utils.failureToastMessage(err?.response?.data?.message);
      } else {
        setCreatedUserDetails(createUserRes);
        if (createUser?.isEmailVerified) {
          Utils.successToastMessage(successMessageConstant.SIGNUP_SUCCESS);
        }
        return createUserRes;
      }
    } catch (error) {
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_CREATING_USER
      );
    } finally {
      /* empty */
    }
  };
  useEffect(() => {
    const handleAuthCallBack = async () => {
      try {
        // Get the hash from the URL
        const hash = window.location.hash;
        // Check if the hash starts with "#access_token"
        if (hash?.startsWith("#access_token")) {
          dispatch(showLoader());
          setCallbackLoader(true);
          // Get the access token from the URL parameters
          const params = new URLSearchParams(hash);
          const accessToken = params.get("#access_token");
          if (accessToken) {
            setLoginPage(true);
          }

          // Get the user info from Auth0Service
          const userInfo = await new Auth0Service().userInfo();
          // Check if the email matches the allowed email
          if (sessionStorage.getItem(EMAIL_ID)) {
            if (userInfo.email !== sessionStorage.getItem(EMAIL_ID)) {
              // Handle the case where the email does not match
              Utils.failureToastMessage("Unauthorized email address");
              dispatch(hideLoader());
              setCallbackLoader(false);
              return;
            }
          }
          const userDetails = Utils.localStorageGetItem(USER);
          const isGoogleLogin = sessionStorage.getItem(IS_GOOGLE_LOGIN);
          let userData;
          // google signup condition start
          if (
            userInfo?.email_verified &&
            userInfo?.sub.includes("google") &&
            !isGoogleLogin
          ) {
            userData = await updateUserDetail(userInfo, userDetails);
            if (userData === undefined) {
              dispatch(hideLoader());
              setCallbackLoader(false);
              return;
            } else {
              dispatch(
                updateUser({
                  ...userData,
                  isLoggedIn: true
                })
              );
              dispatch(hideLoader());
              Utils.successToastMessage(successMessageConstant.SIGIN_SUCCESS);
              setCallbackLoader(false);
              navigate("/personal-details");
              return;
            }
          }
          // google signup condition end

          //google login conditiion start
          if (
            userInfo?.email_verified &&
            userInfo?.sub.includes("google") &&
            isGoogleLogin
          ) {
            // Define a regex pattern to detect special characters
            const encodedEmail = checkEmail(userInfo?.email);
            const [error, userResponse] = await Utils.parseResponse(
              new UserService().checkUserExists({ email: encodedEmail })
            );
            if (userResponse?.user?.userId.includes("auth0")) {
              Utils.failureToastMessage(
                failureMessageConstant.USER_IS_ALREDY_EXIST_PLEASE_USER_EMAIL_LOGIN
              );
              sessionStorage.removeItem(IS_GOOGLE_LOGIN);
              setCallbackLoader(false);
              return;
            }
            if (userResponse == null) {
              Utils.failureToastMessage(
                failureMessageConstant.INCORRECT_EMAIL_PASSWORD
              );
              sessionStorage.removeItem(IS_GOOGLE_LOGIN);
              setCallbackLoader(false);
              return;
            }
            if (userResponse?.user?.userId.includes("google")) {
              userData = await updateUserDetail(userInfo, userResponse?.user);
              if (userData === undefined) {
                dispatch(hideLoader());
                setCallbackLoader(false);
                return;
              } else {
                dispatch(
                  updateUser({
                    ...userData,
                    isLoggedIn: true
                  })
                );
                dispatch(hideLoader());
                sessionStorage.removeItem(IS_GOOGLE_LOGIN);
                Utils.successToastMessage(successMessageConstant.SIGIN_SUCCESS);
                setCallbackLoader(false);
                navigate("/personal-details");
                return;
              }
            }
          }

          //google login conditiion end
          // eslint-disable-next-line camelcase
          const { email_verified } = userInfo;
          if (!userDetails?.isEmailVerified) {
            userData = await updateUserDetail(userInfo, userDetails);
            if (userData === undefined) {
              dispatch(hideLoader());
              setCallbackLoader(false);
              return;
            }
          }
          // Dispatch an action to update the user state
          if (userData) {
            if (userData?.isEmailVerified) {
              dispatch(
                updateUser({
                  ...userData
                })
              );
            } else {
              setCallbackLoader(false);
              setStatusVerificationEmail(true);
              navigate("/");
            }
          }
          // eslint-disable-next-line camelcase
          if (email_verified) {
            const userDetails = Utils.localStorageGetItem(USER);
            dispatch(
              updateUser({
                ...userDetails,
                isLoggedIn: true
              })
            );
            // Utils.successToastMessage(successMessageConstant.SIGIN_SUCCESS);
            setCallbackLoader(false);
            navigate("/user-otp");
          }
          dispatch(hideLoader());
        }
      } catch (err) {
        console.error(err);
        sessionStorage.removeItem(IS_GOOGLE_LOGIN);
        dispatch(hideLoader());
        setCallbackLoader(false);
      }
    };

    handleAuthCallBack();
    // eslint-disable-next-line
  }, []);

  // FUNCTION TO SIGN IN
  const signIn = async (data) => {
    try {
      const { email, password } = data;
      // Sign in using auth0Service
      await new Auth0Service().signIn({ email, password });
    } catch (err) {
      Utils.failureToastMessage(
        err?.error === "too_many_attempts"
          ? failureMessageConstant.ACCOUNT_BLOCKED_CHECK_MAIL_FOR_UNBLOCK_INSTRUCTIONS
          : failureMessageConstant.INCORRECT_EMAIL_PASSWORD
      );
    }
  };

  // FUNCTION TO SIGNUP
  const signUp = async (data) => {
    try {
      const response = await new Auth0Service().signUp({
        name: data.name,
        email: data?.email.toLowerCase(),
        password: data?.password,
        phoneNumber: data?.phoneNumber
      });
      if (response) {
        await signIn(data);
      }
    } catch (err) {
      console.error(err);
      if (err?.code === "invalid_signup") {
        Utils.failureToastMessage(failureMessageConstant.PLEASE_SIGN_IN);
        setAccountExistsSignIn(true);
      }
    } finally {
      /* empty */
    }
  };

  // FUNCTION TO SIGNUP open registration
  const signUpforOpenRegistration = async (data) => {
    try {
      ("signUpforOpenRegistration try block");
      // eslint-disable-next-line
      setPasswordforToken(data?.password);
      const response = await new Auth0Service().signUp({
        name: data.name,
        email: data?.email.toLowerCase(),
        password: data?.password,
        phoneNumber: data?.phoneNumber
      });
      await signIn(data);
      if (response) {
        // Get the user info from Auth0Service
        const userInfo = await new Auth0Service().userInfo();
        const userData = await createUserDetail(response);
        // Dispatch an action to update the user state
        if (userData) {
          if (userData?.isEmailVerified) {
            dispatch(
              updateUser({
                ...userData
              })
            );
          } else {
            setCallbackLoader(false);
            setStatusVerificationEmail(true);
            navigate("/");
          }
        }
      }
    } catch (err) {
      console.error(err);
      if (err?.code === "invalid_signup") {
        Utils.failureToastMessage(failureMessageConstant.PLEASE_SIGN_IN);
        setAccountExistsSignIn(true);
      }
    } finally {
      /* empty */
    }
  };

  const handleOAuthSignIn = async () => {
    if (!loginPage) {
      if (!usersEmail && !id) {
        Utils.failureToastMessage(
          failureMessageConstant.DONOT_HAVE_PERMISSION_TO_SIGNUP
        );
        return;
      }
      const userEmail = usersEmail.toLowerCase();
      // Define a regex pattern to detect special characters
      const encodedEmail = checkEmail(userEmail);
      try {
        dispatch(showLoader());
        const [error, userResponse] = await Utils.parseResponse(
          new UserService().checkUserExists({ email: encodedEmail })
        );
        if (userResponse?.user?.userId.includes("auth0")) {
          Utils.failureToastMessage(
            failureMessageConstant.USER_IS_ALREDY_EXIST_PLEASE_USER_EMAIL_LOGIN
          );
          return;
        }
        if (userResponse?.user?.userId.includes("google")) {
          Utils.failureToastMessage(
            failureMessageConstant.USER_IS_ALREDY_EXIST_PLEASE_USER_GOOGLE_LOGIN
          );
          return;
        }
        if (userResponse == null) {
          if (!Utils.localStorageGetItem(ID) || loginPage) {
            Utils.failureToastMessage(
              failureMessageConstant.DONOT_HAVE_PERMISSION_TO_SIGNUP
            );
            return;
          } else {
            sessionStorage.setItem(EMAIL_ID, usersEmail);
            await new Auth0Service().oAuthSignIn();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      sessionStorage.setItem(IS_GOOGLE_LOGIN, true);
      await new Auth0Service().oAuthSignIn();
    }
  };
  const resendVerifyEmailHandler = async () => {
    try {
      const response = await new UserService().resendVerifyEmail({
        userId: updatedUserDetails?.userId
          ? updatedUserDetails?.userId
          : Utils.localStorageGetItem(AUTH_ID)
      });
      if (!response) {
        Utils.failureToastMessage(
          failureMessageConstant.UNABLE_TO_RESENT_VERIFY_EMAIL
        );
      } else {
        Utils.successToastMessage(
          successMessageConstant.VERIFY_EMAIL_RESENT_SUCCESSFULLY
        );
      }
    } catch (err) {
      console.error(err);
      Utils.failureToastMessage(
        failureMessageConstant.UNABLE_TO_RESENT_VERIFY_EMAIL
      );
    } finally {
      /* empty */
    }
  };

  // FUNCTION TO CHECK USER ALREADY EXISTS OR NOT
  const checkUserAlreadyExistsOrNot = async (data) => {
    sessionStorage.removeItem(EMAIL_ID);
    const { email, password, name } = data;
    if (!email) {
      dispatch(showLoader());
      await resendVerifyEmailHandler();
      dispatch(hideLoader());
      return;
    }
    const userEmail = email.toLowerCase();
    // Define a regex pattern to detect special characters
    const encodedEmail = checkEmail(userEmail);
    try {
      dispatch(showLoader());
      const [error, userResponse] = await Utils.parseResponse(
        new UserService().checkUserExists({ email: encodedEmail })
      );
      if (userResponse?.user?.userId.includes("google")) {
        Utils.failureToastMessage(
          failureMessageConstant.USER_IS_ALREDY_EXIST_PLEASE_USER_GOOGLE_LOGIN
        );
        return;
      }
      Utils.localStorageSetItem(AUTH_ID, userResponse?.user?.userId);
      if (userResponse == null) {
        //  await signUp(data);
        //  await signUpforOpenRegistration(data);
        if (!Utils.localStorageGetItem(ID) && loginPage) {
          await signIn(data);
          //   Utils.failureToastMessage(
          //     failureMessageConstant.DONOT_HAVE_PERMISSION_TO_SIGNIN
          //   );
          //   return;
        }
        if (!Utils.localStorageGetItem(ID) || loginPage) {
          await signUpforOpenRegistration(data);
          // //   Utils.failureToastMessage(
          // //     failureMessageConstant.DONOT_HAVE_PERMISSION_TO_SIGNUP
          // //   );
          // //   return;
        } else {
          await signUp(data);
        }
      } else {
        if (email && password && name) {
          // checking if email, password and name is coming means user
          // is trying to signup throw the error here for signin
          console.error(error);
          Utils.failureToastMessage(
            failureMessageConstant.USER_ALREADY_EXISTS_PLEASE_SIGN_IN
          );
          return;
        } else {
          dispatch(
            updateUser({
              ...userResponse?.user
            })
          );
          // here calling sign in function for user as he is trying to signin
          await signIn(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    <>
      {callbackLoader && <CommonLoader />}
      {!callbackLoader && (
        <LoginComponent
          checkUserAlreadyExistsOrNot={checkUserAlreadyExistsOrNot}
          statusVerificationEmail={statusVerificationEmail}
          loginPage={loginPage}
          isPasswordEmailValid={isPasswordEmailValid}
          usersEmail={usersEmail}
          accountExistsSignIn={accountExistsSignIn}
          setStatusVerificationEmail={setStatusVerificationEmail}
          setLoginPage={setLoginPage}
          resendVerifyEmailHandler={resendVerifyEmailHandler}
          handleOAuthSignIn={handleOAuthSignIn}
        />
      )}
    </>
  );
};

export default Login;
