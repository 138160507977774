/* eslint-disable camelcase */
import auth0 from "auth0-js";
import Cookies from "../managers/sessionManager";
import { apiEndPointConstants, keyConstants } from "../constants";
import HTTPService from "../utility/httpService";
const { ACCESS_TOKEN } = keyConstants;
const { OAUTH_TOKEN } = apiEndPointConstants;

export default class Auth0Service {
  constructor(token) {
    this.httpService = new HTTPService(process.env.REACT_APP_AUTH0_BASEURL);
    this.config = {
      headers: {
        Authorization: `Bearer ${
          token !== undefined
            ? token
            : new Cookies().getCookieValue(ACCESS_TOKEN)
        }`
      }
    };
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirectUri: window.location.origin,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
      responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
      grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE
    });
  }
  signUp({ name, email, password, phoneNumber }) {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: process.env.REACT_APP_AUTH0_REALM,
          relam: process.env.REACT_APP_AUTH0_REALM,
          email: email.toLowerCase(),
          password: password,
          // eslint-disable-next-line camelcase
          given_name: name,
          name: name,
          nickname: name,
          grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          user_metadata: {
            phoneNumber: `+1 ${phoneNumber}`
          }
        },
        (err, authResult) => {
          if (err) return reject(err);
          return resolve(authResult);
        }
      );
    });
  }
  signIn({ email, password }) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: process.env.REACT_APP_AUTH0_REALM,
          email: email.toLowerCase(),
          password: password,
          clientID: process.env.REACT_APP_AUTH0_CLIENT,
          connection: process.env.REACT_APP_AUTH0_REALM,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        },
        (err, authResult) => {
          if (err) return reject(err);
          return resolve(authResult);
        }
      );
    });
  }

  oAuthSignIn() {
    this.auth0.authorize({
      connection: process.env.REACT_APP_AUTH0_CONNECTION,
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE
    });
  }

  userInfo() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((error, authResult) => {
        if (error) return reject(error);
        this.auth0.client.userInfo(
          authResult.accessToken,
          (userError, user) => {
            if (userError) return reject(userError);
            new Cookies().set(ACCESS_TOKEN, authResult?.accessToken);
            resolve(user);
          }
        );
      });
    });
  }

  getToken(obj) {
    // Prepare the data object with the necessary parameters for token retrieval
    const payload = {
      // eslint-disable-next-line camelcase
      grant_type: process.env.REACT_APP_AUTH0_GRANT_TYPE,
      username: obj?.email.toLowerCase(),
      password: obj?.password,
      // eslint-disable-next-line camelcase
      client_id: process.env.REACT_APP_AUTH0_CLIENT,
      // eslint-disable-next-line camelcase
      client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
      connection: process.env.REACT_APP_AUTH0_REALM,
      realm: process.env.REACT_APP_AUTH0_REALM,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    };

    return this.httpService.post(OAUTH_TOKEN, payload, this.config);
  }
  deleteUserFromAuth0(authId) {
    const url = `/api/v2/users/${authId}`;
    // Prepare the data object with the necessary parameters for token retrieval

    return this.httpService.delete(url, null, this.config);
  }
}
