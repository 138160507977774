/* eslint-disable no-console */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  failureMessageConstant,
  keyConstants,
  UPLOAD_DOCUMENTS
} from "../../constants";
import Utils from "../../utility";

const UploadDocuments = ({
  uploadedFiles,
  setUploadedFiles,
  uploadDocumentPage,
  setUploadDocumentPage
}) => {
  const simulateUploadProgress = (index, file) => {
    const interval = setInterval(() => {
      setUploadedFiles((prev) => {
        const updatedFiles = { ...prev };
        const fileIndex = updatedFiles[index]?.findIndex(
          (f) => f.name === file.name
        );
        if (fileIndex !== -1) {
          const progress = updatedFiles[index][fileIndex].progress || 0;
          updatedFiles[index][fileIndex].progress = Math.min(
            progress + 10,
            100
          ); // Increment progress safely
          if (updatedFiles[index][fileIndex].progress >= 100) {
            clearInterval(interval);
          }
        }
        return updatedFiles;
      });
    }, 500);
  };
  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files);
    const pdfFiles = files
      .filter((file) => file.type === "application/pdf")
      .map((file) => ({
        name: file.name || "Unnamed File",
        size: file.size,
        type: file.type,
        progress: 0,
        lastModified: file.lastModified
      }));
    if (pdfFiles.length !== files.length) {
      Utils.failureToastMessage(
        failureMessageConstant.ONLY_PDF_FILE_ARE_ALLOWED
      );
      return;
    }

    setUploadedFiles((prev) => {
      const updatedFiles = { ...prev };
      if (!updatedFiles[index]) {
        updatedFiles[index] = [];
      }
      updatedFiles[index] = [...updatedFiles[index], ...pdfFiles];
      return updatedFiles;
    });
    setUploadDocumentPage(2);
    pdfFiles.forEach((file, i) => simulateUploadProgress(index, file));
  };

  const handleDeleteFile = (index, fileName) => {
    setUploadedFiles((prev) => {
      const updatedFiles = { ...prev };
      updatedFiles[index] = updatedFiles[index].filter(
        (file) => file.name !== fileName
      );
      return updatedFiles;
    });
  };

  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = e.dataTransfer.files;
    handleFileChange(0, { target: { files } });
  };

  return (
    <div className={`${uploadDocumentPage === 2 ? "h-100" : ""}`}>
      {/* {uploadDocumentPage === 1 && ( */}
      <>
        <div className="w-527px h-20px absolute top-249px left-821px opacity-100 mb-2">
          <div className="font-Inter text-14px font-normal leading-[25px] text-center decoration-skip-ink bg-black">
            Please upload MRI, XRAY, CT SCAN, SURGERY REPORT, FIR
            <span className="block">(Optional) Report here.</span>
          </div>
        </div>
        <div
          className="w-531px h-146px border-dashed border border-blue-146EF6 rounded-xl flex justify-center items-center flex-col mt-20px mx-auto"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => document.getElementById("fileInput").click()}
        >
          <div className="w-51px h-51px bg-blue-F3F8FF flex justify-center items-center rounded-full cursor-pointer">
            <img src="/images/plus-icon-upload.svg" alt="plus-icon" />
          </div>
          <input
            id={"fileInput"}
            type="file"
            multiple
            accept=".pdf"
            style={{ display: "none" }}
            onChange={(event) => handleFileChange(0, event)}
          />
          <div className=" font-PoppinsMedium font-medium text-ft14-20 text-black-15 mt-2 cursor-pointer">
            Drag and drop or <span className=" text-blue-146EF6">Browse</span>
          </div>
          <div
            className=" font-PoppinsRegular font-normal text-ft14-20 !text-grey-9FACBA"
            style={{ color: "#9FACBA" }}
          >
            Supported formats : PDF
          </div>
          <div
            className=" font-PoppinsRegular font-normal text-ft14-20 !text-grey-9FACBA"
            style={{ color: "#9FACBA" }}
          >
            Max file size for a single file : 20MB
          </div>
        </div>
      </>
      {/* )} */}

      {/* uploadDocumentPage === 2 && */}
      <div
        className="max-h-249px overflow-y-auto rounded-md p-4"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none"
        }}
      >
        {uploadedFiles[0] &&
          uploadedFiles[0].map((file, fileIndex) => (
            <>
              <div
                key={fileIndex}
                className="mb-1.5 flex items-center justify-between gap-2"
              >
                <div className=" flex items-center gap-1.5">
                  <img
                    src="/images/upload-file-image.svg"
                    alt="uploaded-image"
                    className="w-6.5 h-6.5"
                  />
                  <p className="font-inter w-280px font-normal  text-13px text-black-15">
                    {file.name}
                  </p>
                </div>

                <div className="flex items-center justify-between gap-2">
                  {file.size / (1024 * 1024) > 20 ? (
                    <div className="flex items-center gap-0 ml-auto">
                      <p className="font-inter text-tiny font-normal w-172px leading-14.52 text-[#FF0808]">
                        Invalid file. Exceeds file size limit
                      </p>
                      <img
                        onClick={() => handleDeleteFile(0, file.name)}
                        src="/images/delete-icon.svg"
                        className="cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="grow flex items-center gap-0">
                      <div className="relative w-98px h-5px bg-grey-E5E5E5 rounded">
                        <div
                          className="absolute top-0 left-0 h-5px bg-blue-146EF6 rounded"
                          style={{
                            width: `${!isNaN(file.progress) ? file.progress : 0}%`
                          }}
                        ></div>
                      </div>
                      <img
                        onClick={() => handleDeleteFile(0, file.name)}
                        src="/images/delete-icon.svg"
                        className="cursor-pointer"
                      />
                    </div>
                  )}
                </div>
              </div>
              <hr className="border border-[#E5E5E5] mb-1.5" />
            </>
          ))}
      </div>
    </div>
  );
};

export default UploadDocuments;
